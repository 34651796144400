import * as React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout/layout";

import SEO from "../components/seo";

const NotFoundPage = () => (
  <>
    {/* --- background pattern --- */}
    <div className="absolute w-full bg-bottom bg-no-repeat bg-cover h-5/6 xl:h-5/6 z-n10 bg-hero-pattern-2 lg:h-full "></div>
    <Layout>
      <SEO title="404: Not found" />
      <div className="min-h-full px-4 py-16 bg-white sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="mx-auto max-w-max">
          <main className="sm:flex">
            <p className="text-4xl font-extrabold text-swa-3 sm:text-5xl">
              404
            </p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                  Singapore Women's Association
                </h1>
                <p className="mt-1 text-base text-gray-500">
                  We have recently revamped our website. Some of the links or
                  pages you are searching for have been relocated.
                </p>
                <p className="mt-1 text-base text-gray-500">
                  Find what you are looking for by clicking on the menu options.
                </p>
              </div>
              <div className="flex mt-10 space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <Link
                  to="/"
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-swa-3 hover:bg-swa-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-swa-1"
                >
                  Start from our home page
                </Link>
                <Link
                  to="/forms/contact"
                  className="inline-flex items-center px-4 py-2 text-sm font-medium bg-indigo-100 border border-transparent rounded-md text-swa-3 hover:bg-swa-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-swa-1"
                >
                  Contact us
                </Link>
              </div>
            </div>
          </main>
        </div>
      </div>
    </Layout>
  </>
);

export default NotFoundPage;
